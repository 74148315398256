import { faCircle, faExternalLinkAlt } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import axios from "axios";
import { observer } from "mobx-react-lite";
import React, { useContext, useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import styled from "styled-components";

import {
  Button,
  Container,
  Flex1,
  FlexColumn,
  FlexRow,
  Label,
  Link,
  Paragraph,
  RouterLink,
  TextInput,
} from "../components/base-components";
import LoaderView from "../components/LoaderView";
import LoginFailureModal from "../components/LoginFailureModal";
import Logo from "../components/Logo";
import LogoAnimation from "../components/LogoAnimation";
import OTPModal from "../components/OTPModal";
import UserContext from "../contexts/UserContext";
import { isEmailValid } from "../lib/validation";
import { color } from "../resources/styles";

const AnimatedContainer = styled(Container)`
  position: relative;
  background-image: url("/img/background_resized.png");
  background-position: center;
  background-size: cover;
`;

const MainFlex = styled(Flex1)`
  display: flex;
  align-items: center;
  position: relative;
  @media screen and (min-width: 540px) {
    display: block;
    margin-top: 2rem;
  }
`;

const MainBody = styled(Paragraph)`
  width: 90%;
  margin-left: auto;
  margin-right: auto;
  white-space: pre-wrap;

  opacity: 0;
  animation-duration: 0.8s;
  animation-delay: 4s;
  animation-name: fadeinslidedown;
  animation-fill-mode: forwards;
  animation-timing-function: ease-in-out;
  line-height: 1.4rem;
`;

const Footer = styled(FlexColumn)`
  width: calc(100% + 60px);
  margin-left: -30px;
  margin-right: -30px;
  margin-bottom: -30px;
  padding-top: 15px;
  background-color: #fff;
  opacity: 0;
  animation-duration: 0.8s;
  animation-delay: 4.5s;
  animation-name: fadein;
  animation-fill-mode: forwards;
  animation-timing-function: ease-in-out;
  border-top-left-radius: 15px;
  border-top-right-radius: 15px;
  @media (min-aspect-ratio: 8/5) {
    padding-top: 5px;
  }
`;

const FooterSection = styled.div`
  max-width: 540px;
  width: 100%;
  margin: 10px auto;
  padding: 0 10px;
  text-align: center;
  opacity: 0;
  animation-duration: 1s;
  animation-name: fadeinslideup;
  animation-fill-mode: forwards;
  animation-timing-function: ease-in-out;
  // Animation duration is defined using inline styles on each component instance

  @media (min-aspect-ratio: 8/5) {
    margin: 5px auto;
  }
`;

const LinkIcon = styled(FontAwesomeIcon)`
  margin-right: 10px;
`;

const CircleIcon = styled(FontAwesomeIcon)`
  margin: 3px 10px 0 10px;
  color: ${color.orange};
  font-size: 4px;
`;

const HomeScreen = observer(() => {
  const userContext = useContext(UserContext);

  const history = useHistory();
  const [email, setEmail] = useState("");
  const [isLoggingIn, setIsLoggingIn] = useState(false);
  const [showOTPModal, setShowOTPModal] = useState(false);
  const [showLoginFailureModal, setShowLoginFailureModal] = useState(false);

  useEffect(() => {
    userContext.trackEvent("VIEW_HOME");
    userContext.loadAppSettings();
  }, []);

  const onSubmit = async () => {
    if (userContext.appSettings.login_method === "none") {
      userContext.emailAddress = email;
      userContext.trackEvent("LOGIN_DIRECT");
      history.push("/app");
    } else if (isEmailValid(email)) {
      try {
        setIsLoggingIn(true);
        let authEndpoint = `${process.env.REACT_APP_BACKEND_ENDPOINT}/api/login/email`;
        if (userContext.appSettings.login_method === "code") {
          authEndpoint = `${process.env.REACT_APP_BACKEND_ENDPOINT}/api/otp`;
        }
        const response = await axios.post(authEndpoint, {
          email,
        });
        const result = response.data;
        setIsLoggingIn(false);
        if (
          userContext.appSettings.login_method === "code" &&
          result.otp_required !== false
        ) {
          userContext.trackEvent("SEND_OTP");
          setShowOTPModal(true);
        } else {
          // We got this far without an error, let the user proceed
          userContext.emailAddress = email;
          if (userContext.appSettings.login_method === "code") {
            userContext.trackEvent("LOGIN_SKIP_OTP");
          } else {
            userContext.trackEvent("LOGIN_EMAIL");
          }

          history.push("/app");
        }
      } catch (e) {
        setIsLoggingIn(false);
        if (e.response.status === 403) {
          // ACCESS DENIED - TO0 MANY USES!
          userContext.trackEvent("LOGIN_DENIED");
          history.replace("/403");
        } else {
          setShowLoginFailureModal(true);
        }
      }
    }
  };

  const onSubmitOTP = async (code: string) => {
    try {
      setIsLoggingIn(true);
      const authEndpoint = `${process.env.REACT_APP_BACKEND_ENDPOINT}/api/login/otp`;
      await axios.post(authEndpoint, {
        email,
        otp: code,
      });
      setIsLoggingIn(false);
      // We got this far without an error, let the user proceed
      userContext.emailAddress = email;
      userContext.otpCode = code;
      userContext.trackEvent("LOGIN_OTP");
      history.push("/app");
    } catch (e) {
      setIsLoggingIn(false);
      if (e.response.status === 403) {
        // ACCESS DENIED - TO0 MANY USES!
        userContext.trackEvent("LOGIN_DENIED");
        history.replace("/403");
      } else {
        setShowLoginFailureModal(true);
      }
    }
  };

  const onHideLoginFailureModal = () => {
    setShowLoginFailureModal(false);
    setShowOTPModal(true);
  };

  const onHideOTPModal = () => {
    setShowOTPModal(false);
  };

  const handleKeyDown = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (event.key === "Enter") {
      onSubmit();
    }
  };

  if (userContext.appSettingsLoading) {
    return <LoaderView />;
  }

  return (
    <>
      {isLoggingIn && <LoaderView />}
      {showLoginFailureModal && (
        <LoginFailureModal onHide={onHideLoginFailureModal} />
      )}
      {showOTPModal && (
        <OTPModal onHide={onHideOTPModal} onSubmit={onSubmitOTP} />
      )}
      <AnimatedContainer justify={"space-between"}>
        <Logo animated={true} />
        <LogoAnimation />
        <MainFlex>
          <MainBody align={"center"}>
            {userContext.appSettings.welcome_message}
          </MainBody>
        </MainFlex>
        <Footer>
          <FooterSection style={{ animationDelay: "4.5s" }}>
            {userContext.appSettings.login_method !== "none" && (
              <>
                <Label>Enter your email to continue</Label>
                <TextInput
                  placeholder={"john.smith@gmail.com"}
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                  onKeyDown={handleKeyDown}
                />
              </>
            )}
            <Button
              disabled={
                userContext.appSettings.login_method !== "none" &&
                !isEmailValid(email)
              }
              onClick={onSubmit}
            >
              {userContext.appSettings.start_btn_text}
            </Button>
          </FooterSection>
          <FooterSection style={{ animationDelay: "4.7s" }}>
            <Link
              align={"center"}
              transform={"uppercase"}
              weight={"bold"}
              href={"https://apolloblinds.com.au/"}
              target={"_blank"}
            >
              <LinkIcon icon={faExternalLinkAlt} />
              Visit Our Website
            </Link>
          </FooterSection>
          <FooterSection style={{ animationDelay: "4.9s" }}>
            <FlexRow align={"center"} justify={"center"}>
              <RouterLink to={"/privacy"}>Privacy</RouterLink>
              <CircleIcon icon={faCircle} />
              <RouterLink to={"/terms"}>Terms</RouterLink>
              <CircleIcon icon={faCircle} />
              <Link
                href={"https://apolloblinds.com.au/contact/"}
                target={"_blank"}
              >
                Contact
              </Link>
            </FlexRow>
          </FooterSection>
        </Footer>
      </AnimatedContainer>
    </>
  );
});

export default HomeScreen;
