import React from "react";
import { BrowserRouter as Router, Route } from "react-router-dom";
import { AnimatedSwitch } from "react-router-transition";

import AccessDeniedScreen from "../screens/AccessDeniedScreen";
import HomeScreen from "../screens/HomeScreen";
import PrivacyScreen from "../screens/PrivacyScreen";
import TermsScreen from "../screens/TermsScreen";
import VisualiserScreen from "../screens/VisualiserScreen";

export default function RootRouter(): JSX.Element {
  return (
    <Router>
      <AnimatedSwitch
        atEnter={{ opacity: 0 }}
        atLeave={{ opacity: 0 }}
        atActive={{ opacity: 1 }}
        className="switch-wrapper"
      >
        <Route path="/faq">
          <div>
            <p>FAQs</p>
          </div>
        </Route>
        <Route path="/privacy">
          <PrivacyScreen />
        </Route>
        <Route path="/terms">
          <TermsScreen />
        </Route>
        <Route path="/app/:complete?">
          <VisualiserScreen />
        </Route>
        <Route path="/403">
          <AccessDeniedScreen />
        </Route>
        <Route path="/" exact>
          <HomeScreen />
        </Route>
      </AnimatedSwitch>
    </Router>
  );
}
