import { faExternalLinkAlt, faUndo } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { observer } from "mobx-react-lite";
import React, {useContext, useEffect} from "react";
import { useHistory } from "react-router-dom";
import styled from "styled-components";

import CameraContext from "../contexts/CameraContext";
import ProductContext from "../contexts/ProductContext";
import UserContext from "../contexts/UserContext";
import {
  Button,
  ButtonAlt,
  ButtonIcon,
  Flex0,
  FlexColumn,
  Link,
  TitleSmall,
} from "./base-components";
import Logo from "./Logo";

const Container = styled.div`
  max-width: 540px;
  margin: 10px auto;
  padding: 0 10px;
  text-align: center;
  flex: 1;
  width: 100%;
  display: flex;
  flex-direction: column;
`;

const Content = styled.div`
  height: 100%;
  flex: 1;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

const SuccessMessage = styled(TitleSmall)`
  text-align: center;
  white-space: pre-wrap;
  text-transform: none;
`;

const LinkIcon = styled(FontAwesomeIcon)`
  margin-right: 10px;
`;

const RestartButton = styled(ButtonAlt)`
  padding-left: 40px;
  padding-right: 40px;
  margin-bottom: 10px;
`;

const SubmissionComplete = observer(() => {
  const history = useHistory();
  const userContext = useContext(UserContext);
  const productContext = useContext(ProductContext);
  const cameraContext = useContext(CameraContext);

  useEffect(() => {
    userContext.trackEvent("VIEW_COMPLETE");
  }, []);

  const onPressRestart = () => {
    // RESET EVERYTHING!
    cameraContext.reset();
    userContext.reset();
    productContext.reset();
    history.replace("/");
  };

  const onClickScreenshot = () => {
    const screenshotCanvas = document.createElement("canvas");
    const screenshotContext = screenshotCanvas
      ? screenshotCanvas.getContext("2d")
      : null;

    const cameraCanvas = document.querySelector(
      "#camera-canvas-wrapper canvas"
    ) as HTMLCanvasElement;

    const dummyCanvas0 = document.querySelector(
      "#dummy-canvas-0"
    ) as HTMLCanvasElement;
    const dummyContext0 = dummyCanvas0 ? dummyCanvas0.getContext("2d") : null;

    const dummyCanvas1 = document.querySelector(
      "#dummy-canvas-1"
    ) as HTMLCanvasElement;
    const dummyContext1 = dummyCanvas1 ? dummyCanvas1.getContext("2d") : null;

    const dummyCanvas2 = document.querySelector(
      "#dummy-canvas-2"
    ) as HTMLCanvasElement;
    const dummyContext2 = dummyCanvas2 ? dummyCanvas2.getContext("2d") : null;

    if (
      screenshotContext &&
      cameraCanvas &&
      dummyContext0 &&
      dummyContext1 &&
      dummyContext2
    ) {
      screenshotCanvas.width = cameraCanvas.width;
      screenshotCanvas.height = cameraCanvas.height;

      screenshotContext.drawImage(cameraCanvas, 0, 0);

      // On some devices the camera image is at a different scale to the dummy canvas,
      // Tell the screenshot canvas to scale up/down accordingly before rendering products
      const xScale = screenshotCanvas.width / dummyCanvas0.width;
      const yScale = screenshotCanvas.height / dummyCanvas0.height;

      screenshotContext.scale(xScale, yScale);
      screenshotContext.drawImage(dummyCanvas0, 0, 0);
      screenshotContext.drawImage(dummyCanvas1, 0, 0);
      screenshotContext.drawImage(dummyCanvas2, 0, 0);

      const img = screenshotCanvas.toDataURL("image/png");
      // this can be used to download any image from webpage to local disk
      const xhr = new XMLHttpRequest();
      xhr.responseType = "blob";
      xhr.onload = function () {
        const a = document.createElement("a");
        a.href = window.URL.createObjectURL(xhr.response);
        a.download =
          "Apollo-HomeView-Screenshot-" + new Date().getTime() + ".png";
        a.style.display = "none";
        document.body.appendChild(a);
        a.click();
        a.remove();
      };
      xhr.open("GET", img);
      xhr.send();
    }
  };

  return (
    <Container>
      <FlexColumn align={"center"} flex={1}>
        <Flex0>
          <Logo />
        </Flex0>
        <Content>
          <SuccessMessage>
            {userContext.appSettings.submit_success_message}
          </SuccessMessage>
        </Content>
        <Flex0>
          <RestartButton onClick={onPressRestart}>
            <ButtonIcon icon={faUndo} />
            {userContext.appSettings.start_over_btn_text}
          </RestartButton>
          <Button onClick={onClickScreenshot}>
            {userContext.appSettings.screenshot_btn_text}
          </Button>
          <Link
            align={"center"}
            transform={"uppercase"}
            weight={"bold"}
            href={"https://apolloblinds.com.au/"}
            target={"_blank"}
            style={{ marginTop: "10px" }}
          >
            <LinkIcon icon={faExternalLinkAlt} />
            Visit Our Website
          </Link>
        </Flex0>
      </FlexColumn>
    </Container>
  );
});

export default SubmissionComplete;
