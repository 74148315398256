import { faCamera, faSlash } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { observer } from "mobx-react-lite";
import React, { useContext, useEffect, useRef } from "react";
import Webcam from "react-webcam";
import styled from "styled-components";

import CameraContext from "../contexts/CameraContext";
import ProductContext from "../contexts/ProductContext";
import UserContext from "../contexts/UserContext";
import { color } from "../resources/styles";
import { FullscreenView } from "./base-components";
import CameraTipsModal from "./CameraTipsModal";

const Container = styled(FullscreenView)`
  background-color: ${color.lightgrey};
  position: relative;
`;

const OverlayImage = styled.img`
  position: absolute;
`;

const NoCameraContainer = styled.div`
  position: absolute;
  width: 200px;
  height: 200px;
  border-radius: 125px;
  background-color: ${color.grey};
  left: 0;
  right: 0;
  margin: auto;
  top: calc(50% - 65px);
  transform: translateY(-50%);

  & > .fa-layers {
    width: 100%;
    height: 100%;
  }

  @media (min-aspect-ratio: 8/5) {
    width: 100px;
    height: 100px;
  }
`;

const NoCameraIcon = styled(FontAwesomeIcon)`
  font-size: 100px;
  @media (min-aspect-ratio: 8/5) {
    font-size: 50px;
  }
`;

const CameraView = observer(() => {
  const userContext = useContext(UserContext);
  const cameraContext = useContext(CameraContext);
  const productContext = useContext(ProductContext);

  const webcamRef = useRef<Webcam & HTMLVideoElement>(null);

  useEffect(() => {
    cameraContext.setWebcamRef(webcamRef);
    return () => {
      cameraContext.setWebcamRef(null);
    };
  }, [webcamRef, cameraContext.warningAccepted, cameraContext.cameraError]);

  useEffect(() => {
    userContext.trackEvent("VIEW_CAMERA");
    // Start preloading images as soon as possible
    productContext.preloadCategoryImages();
    // productContext.preloadTopLevelProductsDefaultWindowSize();
  }, []);

  const onUserMedia = () => {
    cameraContext.startCamera();
  };

  const onUserMediaError = () => {
    cameraContext.handleCameraError();
  };

  return (
    <Container>
      {cameraContext.warningAccepted && !cameraContext.cameraError ? (
        <>
          {!userContext.hasDismissedCameraModal && <CameraTipsModal />}

          <div className={"video-container"}>
            <Webcam
              audio={false}
              height={"100%"}
              width={"100%"}
              ref={webcamRef}
              screenshotFormat="image/jpeg"
              videoConstraints={{
                facingMode: "environment",
              }}
              onUserMedia={onUserMedia}
              onUserMediaError={onUserMediaError}
            />
            {cameraContext.cameraImage && (
              <OverlayImage src={cameraContext.cameraImage} />
            )}
          </div>
        </>
      ) : (
        <NoCameraContainer>
          <div className="fa-layers fa-fw">
            <NoCameraIcon icon={faCamera} color={color.lightgrey} />
            <NoCameraIcon icon={faSlash} color={color.lightgrey} />
          </div>
        </NoCameraContainer>
      )}
    </Container>
  );
});
export default CameraView;
