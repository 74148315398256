import { faCheck, faTimes } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { observer } from "mobx-react-lite";
import React, { useContext, useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import styled from "styled-components";

import {
  Button,
  CenteredView,
  FullscreenView,
} from "../components/base-components";
import CameraCapture from "../components/CameraCapture";
import CameraView from "../components/CameraView";
import CanvasView from "../components/CanvasView";
import CategorySelector from "../components/CategorySelector";
import Collapsable from "../components/Collapsable";
import GetAQuoteModal from "../components/GetAQuoteModal";
import LoaderView from "../components/LoaderView";
import WindowCapture from "../components/WindowCapture";
import CameraContext from "../contexts/CameraContext";
import ProductContext from "../contexts/ProductContext";
import UserContext from "../contexts/UserContext";
import arrow from "../resources/img/arrow.png";
import { color } from "../resources/styles";
import LeadSubmissionScreen from "./LeadSubmissionScreen";

const BottomBar = styled.div<{ fullWidth?: boolean; noBg?: boolean }>`
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  border-top-left-radius: 15px;
  border-top-right-radius: 15px;
  background-color: ${color.white};
  padding: 10px 10px 0 10px;
  transition: all 1s;
  z-index: 10;

  @media (min-aspect-ratio: 8/5) {
    width: 100%;
    max-width: ${(props) => (props.fullWidth ? "100%" : "500px")};
    background-color: ${(props) => (props.noBg ? "transparent" : color.white)};
    margin: auto;
  }
`;

const LeftButton = styled(Button)`
  position: absolute;
  left: 10px;
  top: 10px;
  width: auto;
  padding: 10px;
  margin: 0;
  z-index: 10;
  background-color: transparent;
  display: flex;
  align-items: center;
`;

const RightButton = styled(Button)`
  position: absolute;
  right: 10px;
  top: 10px;
  width: auto;
  padding: 10px;
  margin: 0;
  z-index: 10;
  background-color: #fff;
  text-align: center;
  color: ${color.orange};
  display: flex;
  align-items: center;

  opacity: 0;

  animation-duration: 0.3s;
  animation-name: fadeinslidedown;
  animation-fill-mode: forwards;
  animation-timing-function: ease-in-out;
`;

const CloseIcon = styled(FontAwesomeIcon)`
  font-size: 2rem;
  margin-right: 8px;
`;
const CheckContainer = styled.div`
  width: 30px;
  height: 30px;
  margin-right: 8px;
  padding-top: 1px;
  border: 3px solid ${color.orange};
  border-radius: 50%;
`;
const CheckIcon = styled(FontAwesomeIcon)`
  font-size: 0.8rem;
  color: ${color.orange};
`;

const ArrowImg = styled.img`
  width: 100px;
  height: 100px;
  position: absolute;
  right: 90px;
  top: 30px;
  z-index: 10;

  opacity: 0;

  animation-duration: 3s;
  animation-delay: 1s;
  animation-name: arrowshow;
  animation-fill-mode: forwards;
  animation-timing-function: ease-in-out;
`;

const VisualiserScreen = observer(() => {
  const history = useHistory();
  const userContext = useContext(UserContext);
  const cameraContext = useContext(CameraContext);
  const productContext = useContext(ProductContext);

  // Bottom Bar collapsed state
  const [isMinimised, setIsMinimised] = useState(true);
  const [isArrowShown, setIsArrowShown] = useState(false);

  useEffect(() => {
    userContext.trackEvent("VIEW_VISUALISER");
  }, []);

  useEffect(() => {
    if (
      userContext.appSettings.login_method !== "none" &&
      !userContext.emailAddress
    ) {
      history.replace("/");
    }
  }, [userContext.emailAddress]);

  useEffect(() => {
    productContext.reset();
    productContext.loadAll().then(() => {
      productContext.addWindow();
    });
  }, [productContext]);

  const onClickBack = () => {
    if (productContext.hasConfirmedWindows) {
      productContext.setHasConfirmedWindows = false;
    } else if (cameraContext.imageConfirmed) {
      const result = confirm(
        "Take a new picture? Any changes made will be lost."
      );
      if (result) {
        cameraContext.imageConfirmed = false;
        cameraContext.resetImage();
        productContext.reset();
        productContext.addWindow();
      }
    } else {
      const result = confirm(
        "Are you sure want to close the Apollo HomeView app?"
      );
      if (result) {
        productContext.reset();
        cameraContext.reset();
        history.replace("/");
      }
    }
  };

  const onClickWindowConfirmed = () => {
    setIsMinimised(false);
    setIsArrowShown(true);
    userContext.setGetAQuoteModalVisible = true;
  };

  const onCollapsableChange = (isMinimised: boolean) => {
    setIsMinimised(isMinimised);
  };

  const onClickStage = () => {
    if (productContext.hasConfirmedWindows) {
      setIsMinimised(!isMinimised);
    }
  };

  const onClickWindow = (windowIndex: number) => {
    if (
      productContext.hasConfirmedWindows &&
      (isMinimised || windowIndex === productContext.activeWindowIndex)
    ) {
      setIsMinimised(!isMinimised);
    }
  };

  const onClickWindowConfirm = () => {
    if (isMinimised) {
      setIsMinimised(false);
    } else {
      productContext.setHasConfirmedWindows = true;
    }
    // productContext.preloadTopLevelProductsAllWindows();
  };

  const showCheckIcon = () => {
    // return true;
    return (
      cameraContext.imageConfirmed &&
      productContext.hasConfirmedWindows &&
      productContext.allWindowsHaveProductSelections
    );
  };

  if (userContext.isQuoteRequestSubmitting) {
    return <LoaderView message={"Submitting request. Please wait..."} />;
  }

  if (!productContext.getAllLoaded) {
    return <LoaderView />;
  }

  return (
    <FullscreenView>
      {userContext.isGetAQuoteModalVisible && <GetAQuoteModal />}

      <LeftButton onClick={onClickBack}>
        <CloseIcon icon={faTimes} />
        <span>Exit</span>
      </LeftButton>

      {showCheckIcon() && (
        <>
          <RightButton onClick={onClickWindowConfirmed}>
            <CheckContainer>
              <CheckIcon icon={faCheck} />
            </CheckContainer>
            <span>Done</span>
          </RightButton>
          {!isArrowShown && <ArrowImg src={arrow} />}
        </>
      )}
      {!cameraContext.imageConfirmed && <CameraView />}
      {cameraContext.imageConfirmed && (
        <CanvasView
          onClickStage={onClickStage}
          onClickWindow={onClickWindow}
          onClickWindowConfirm={onClickWindowConfirm}
        />
      )}

      <BottomBar
        fullWidth={userContext.isSubmittingLead}
        noBg={!cameraContext.imageConfirmed}
      >
        {!cameraContext.imageConfirmed && <CameraCapture />}
        {cameraContext.imageConfirmed && (
          <Collapsable
            collapsable={!userContext.isSubmittingLead}
            animateResize={true}
            isMinimised={isMinimised}
            onChange={onCollapsableChange}
          >
            <>
              {userContext.isSubmittingLead ? (
                <LeadSubmissionScreen />
              ) : !productContext.hasConfirmedWindows ? (
                <CenteredView>
                  <WindowCapture />
                </CenteredView>
              ) : (
                <>
                  {[...Array(productContext.numWindows)].map((value, index) => {
                    return (
                      <CategorySelector
                        key={`selector-${index}`}
                        windowIndex={index}
                        parentCategory={null}
                        visible={index === productContext.activeWindowIndex}
                      />
                    );
                  })}
                </>
              )}
            </>
          </Collapsable>
        )}
      </BottomBar>
    </FullscreenView>
  );
});

export default VisualiserScreen;
